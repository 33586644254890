<template>
  <div>
    <Notifications />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
const defaultLayout = "public-layout";
import PublicLayout from "./layouts/PublicLayout.vue";

export default {
  components: {
    PublicLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
  },
};
</script>
