import { createRouter, createWebHistory } from "vue-router";
import Signin from "../views/Signin.vue";

const publicRoutes = [
  { path: "/", redirect: "/signin" },
  {
    path: "/signin",
    component: Signin,
  },
  {
    path: "/file-a-claim",
    redirect: () => {
      window.location.href = `${process.env.VUE_APP_FRONTEND_BASE_CUSTOMER_URL}/file-a-claim`;
    },
  },
  {
    path: "/onboarding/start",
    redirect: () => {
      window.location.href = `${process.env.VUE_APP_FRONTEND_BASE_STORE_URL}/onboarding/start`;
    },
  },
  {
    path: "/contracts-details",
    component: Signin,
    redirect: () => {
      window.location.href = `${process.env.VUE_APP_FRONTEND_BASE_CUSTOMER_URL}/contracts-details`;
    },
  },
  {
    path: "/:merchantShop/subscription",
    redirect: (to) => {
      window.location.href = `${process.env.VUE_APP_FRONTEND_BASE_CUSTOMER_URL}/${to.params.merchantShop}/subscription`;
    },
  },
  {
    path: "/plan-details/:contractSku",
    redirect: (to) => {
      window.location.href = `${process.env.VUE_APP_FRONTEND_BASE_CUSTOMER_URL}/plan-details/${to.params.contractSku}`;
    },
  },
  {
    path: "/subscription/:uniqueToken/form/:productId/:contractId",
    redirect: (to) => {
      window.location.href = `${process.env.VUE_APP_FRONTEND_BASE_CUSTOMER_URL}/subscription/${to.params.uniqueToken}/form/${to.params.productId}/${to.params.contractId}`;
    },
  },
  {
    path: "/subscription/:uniqueToken",
    redirect: (to) => {
      window.location.href = `${process.env.VUE_APP_FRONTEND_BASE_CUSTOMER_URL}/subscription/${to.params.uniqueToken}`;
    },
  },
  {
    path: "/file-a-claim/:id/:date",
    redirect: (to) => {
      window.location.href = `${process.env.VUE_APP_FRONTEND_BASE_CUSTOMER_URL}/file-a-claim/${to.params.id}/${to.params.date}`;
    },
  },
];

const routes = publicRoutes;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  if (
    (process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "demo" ||
      process.env.NODE_ENV === "staging") &&
    location.protocol !== "https:"
  ) {
    location.replace(
      `https:${location.href.substring(location.protocol.length)}`
    );
  }
});

router;

export default router;
