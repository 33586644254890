<template>
  <div
    class="min-h-full flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8 mt-12">
      <div>
        <img
          class="mx-auto h-24 w-auto"
          src="https://estaly-docs.s3.eu-west-3.amazonaws.com/Logo_Estaly+-+MiniatureBleu.png"
          alt="Estaly"
        />
        <h2 class="mt-6 text-center text-2xl font-bold text-gray-700">
          Connectez vous à votre espace
        </h2>
      </div>
    </div>
    <ul
      role="list"
      class="mt-6 w-1/2 grid grid-cols-1 gap-x-1 lg:gap-x-4 gap-y-8 md:gap-y-24 lg:grid-cols-2 xl:gap-x-24 place-items-center sm:p-12"
    >
      <div
        class="cursor-pointer group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
        v-on:click="customer_dasbhoard"
      >
        <img
          src="@/assets/logo_customer.png"
          alt=""
          class="pointer-events-none object-cover group-hover:opacity-75"
        />
        <h3 class="mt-2 text-center text-xl font-light text-gray-900">
          Espace client
        </h3>
      </div>
      <div
        class="cursor-pointer group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
        v-on:click="merchant_dashboard"
      >
        <img
          src="@/assets/logo_store.png"
          alt=""
          class="pointer-events-none object-cover group-hover:opacity-75"
        />
        <h3 class="mt-2 text-center text-xl font-light text-gray-900">
          Espace partenaire
        </h3>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    merchant_dashboard: function () {
      window.location=process.env.VUE_APP_FRONTEND_BASE_STORE_URL;
    },
    customer_dasbhoard: function () {
      window.location=process.env.VUE_APP_FRONTEND_BASE_CUSTOMER_URL;
    },
  },
};
</script>
