import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import { createI18n } from "vue-i18n/index";
import { loadLocaleMessages } from "./i18n";

const messages = loadLocaleMessages();

const i18n = createI18n({
  locale: "fr",
  messages,
  globalInjection: true,
});

const app = createApp(App);

app.use(router);
app.use(i18n);
app.mount("#app");
